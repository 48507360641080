import React from 'react';
import NavbarDashboard from '../components/Dashboard/Navbar';
import Register from '../components/Dashboard/Register';

function Usuarios() {


  return (
    <div>
      <NavbarDashboard />
        <Register/>
    </div>
  );
}

export default Usuarios;
